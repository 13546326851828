import React, { Component } from 'react'
import { StyleHeader } from './style'

import { Link } from 'gatsby'

import Logo from '../../images/logo-inovafarma.svg'

class ComponentHeader extends Component {
  activeMenu = e => {
    e.preventDefault()
    this.setState({ activeMenu: !this.state.activeMenu })
  }
  state = {
    activeMenu: false,
  }
  render() {
    return (
      <StyleHeader menuMobile={this.state.activeMenu}>
        <div className="container">
          <Link to="/" className="logo">
            <img src={Logo} alt="Logo Inovafarma" />
          </Link>
          <nav>
            <ul>
              <li>
                <Link to="/solucoes/" activeClassName="active" partiallyActive={true}>
                  Soluções
                </Link>
              </li>
              {/*
              <li>
                  <Link to="/clube-vantagens" activeClassName="active" onClick={this.hiddeMenu}>Clube de vantagens</Link>
              </li>
              */}
              <li>
                <Link to="/sobre/" activeClassName="active" partiallyActive={true} onClick={this.hiddeMenu}>
                  Sobre
                </Link>
              </li>
              <li>
                <Link to="/conteudo/" activeClassName="active" partiallyActive={true} onClick={this.hiddeMenu}>
                  Conteúdos
                </Link>
              </li>
              <li>
                <a href="https://universidade.inovafarma.com.br/" target="_blank" rel="noopener noreferrer">
                  Universidade
                </a>
              </li>
              <li>
                <a href="https://www.inovafarma.com.br/blog/" target="_blank" rel="noopener noreferrer">
                  Blog
                </a>
              </li>
            </ul>

            {
              //<ComponentBusca/>
            }

            <a id="js-open-menu" className="menu-button" role="button" onClick={this.activeMenu}>
              <i className="menu-icon"></i>
            </a>
            <Link to="/sobre/contato" className="btn">
              Solicite uma demonstração
            </Link>
          </nav>
        </div>
        <div className="menu-mobile">
          <ul>
            <li>
              <Link to="/solucoes/" activeClassName="active" partiallyActive={true}>
                Soluções
              </Link>
            </li>
            {/*
            <li><Link to="/clube-vantagens" state={{ activeMenu: false }} activeClassName="active">Clube de vantagens</Link></li>
            */}
            <li>
              <Link to="/sobre/" activeClassName="active" partiallyActive={true} onClick={this.hiddeMenu} onClick={this.hiddeMenu}>
                Sobre
              </Link>
            </li>
            <li>
              <Link to="/conteudo/" activeClassName="active" partiallyActive={true} onClick={this.hiddeMenu}>
                Conteúdos
              </Link>
            </li>
            <li>
              <a href="https://universidade.inovafarma.com.br/" target="_blank" rel="noopener noreferrer">
                Universidade
              </a>
            </li>
            <li>
              <a href="https://www.inovafarma.com.br/blog/" target="_blank" rel="noopener noreferrer">
                Blog
              </a>
            </li>
          </ul>
        </div>
      </StyleHeader>
    )
  }
}

export default ComponentHeader
