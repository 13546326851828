import styled from 'styled-components'

export const ContentFooter = styled.footer`
  padding-top: ${props => (props.padding_lg ? '200px' : '88px')};
  padding-bottom: 48px;
  @media (max-width: 480px) {
    padding: 40px 0px;
    padding-top: ${props => (props.padding_lg ? '220px' : '40px')};
  }
`
