import Cookies from 'js-cookie'

export function getRdTrackId() {
  const rdTrack = Cookies.get('rdtrk')
  if (!rdTrack) return ''

  const id = JSON.parse(rdTrack)?.id
  if (!id) return ''

  return id
}

export function getTrackingSource() {
  const trackingSource = Cookies.get('__trf.src')
  if (!trackingSource) return ''

  return trackingSource
}
