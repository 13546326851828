import React, { Component, useState } from 'react'
import { Link } from 'gatsby'

import { ContentFooter } from './style'

import { getRdTrackId, getTrackingSource } from 'utils/cookies'

import Logo from 'images/logo-inovafarma.svg'
import ArrowSubmit from 'images/arrow-submit.svg'
import LogoInsany from 'images/logo-insany.svg'
import Facebook from 'images/facebook.svg'
import Instagram from 'images/instagram.svg'
import Youtube from 'images/youtube.svg'

import SucessoNewsletter from 'images/sucesso-newsletter.svg'

import { Mutation } from 'react-apollo'
import gql from 'graphql-tag'

const NEWSLETTER_MUTATION = gql`
  mutation EnviarEmail($email: String!, $clientTrackingId: String, $trafficSource: String) {
    createNewsletter(input: { data: { email: $email, clientTrackingId: $clientTrackingId, trafficSource: $trafficSource } }) {
      newsletter {
        createdAt
      }
    }
  }
`

const Newsletter = () => {
  // These state variables will hold the form values when it is filled out
  const [emailValue, setEmailValue] = useState('')

  return (
    <Mutation mutation={NEWSLETTER_MUTATION}>
      {(createNewsletter, { loading, error, data }) => (
        <React.Fragment>
          {data ? (
            <div className="sucesso-newsletter">
              <div className="resposta">
                <span>Enviado</span>
                <div className="icon">
                  <img src={SucessoNewsletter} alt="Icone sucesso" />
                </div>
              </div>
            </div>
          ) : (
            <form
              id="form_newsletter"
              name="form_newsletter"
              onSubmit={async event => {
                // when the form is submitted, we will run the signUp function passed down
                // the Mutation component, and pass it the form values from the state variables
                event.preventDefault()
                createNewsletter({
                  variables: {
                    email: emailValue,
                    clientTrackingId: getRdTrackId(),
                    trafficSource: getTrackingSource(),
                  },
                })
              }}
            >
              <input
                type="email"
                name="email"
                placeholder="Digite seu e-mail"
                value={emailValue}
                onChange={event => {
                  setEmailValue(event.target.value)
                }}
              />
              <button type="submit">
                <img src={ArrowSubmit} alt="Icone seta enviar" />
              </button>
            </form>
          )}
        </React.Fragment>
      )}
    </Mutation>
  )
}
class Footer extends Component {
  render() {
    return (
      <ContentFooter padding_lg={this.props.padding_maior}>
        <div className="container">
          <div className="main-area">
            <div className="inova">
              <img src={Logo} className="logo" alt="Logo Inovafarma" />
              <p>©INOVAFARMA. Simplificando e Inovando Sempre. Todos os direitos reservados. </p>
              <div className="line"></div>
              <span>CNPJ 02.433.981/0001-96</span>
            </div>
            <nav>
              <div className="item">
                <h3>Soluções</h3>
                <ul>
                  <li>
                    <Link to="/solucoes/">Gestão de Vendas</Link>
                  </li>
                  <li>
                    <Link to="/solucoes/controle-estoque">Controle de Estoque</Link>
                  </li>
                  <li>
                    <Link to="/solucoes/controle-financeiro">Controle Financeiro</Link>
                  </li>
                  <li>
                    <Link to="/solucoes/gestao-performance">Gestão de Performance</Link>
                  </li>
                  <li>
                    <Link to="/solucoes/organizacao-fiscal">Organização Fiscal</Link>
                  </li>
                  <li>
                    <Link to="/solucoes/solucoes-complementares">Soluções Complementares</Link>
                  </li>
                </ul>
              </div>
              <div className="item">
                <h3>Utilitários</h3>
                <ul>
                  {/*
                    <li><Link to="/clube-vantagens">Clube de vantagens</Link></li>
                  */}
                  <li>
                    <Link to="/conteudo/cursos">Conteúdo</Link>
                  </li>
                  <li>
                    <a href="https://universidade.inovafarma.com.br/" target="_blank" rel="noopener noreferrer">
                      Universidade
                    </a>
                  </li>
                  <li>
                    <a href="https://www.inovafarma.com.br/blog/" target="_blank" rel="noopener noreferrer">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
              <div className="item">
                <h3>Sobre</h3>
                <ul>
                  <li>
                    <Link to="/sobre/">Quem Somos</Link>
                  </li>
                  <li>
                    <Link to="/sobre/contato">Contato</Link>
                  </li>
                  <li>
                    <Link to="/sobre/representante">Quero ser um representante</Link>
                  </li>
                  <li>
                    <Link to="/sobre/perguntas-frequentes">Perguntas frequentes </Link>
                  </li>
                </ul>
              </div>
            </nav>
            <div className="news">
              <p>Receba conteúdos do mercado e varejo farmacêutico no seu e-mail</p>
              <h2>Assine nossa newsletter</h2>
              <Newsletter />
              <ul>
                <li>
                  <a href="https://www.facebook.com/SistemaInovaFarma/" target="_blank" rel="noopener noreferrer">
                    <img src={Facebook} alt="Icone Facebook" />
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/sistemainovafarma/" target="_blank" rel="noopener noreferrer">
                    <img src={Instagram} alt="Icone Instagram" />
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCkMlMaX65X_tMDHR8luXagQ" target="_blank" rel="noopener noreferrer">
                    <img src={Youtube} alt="Icone Youtube" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="copy">
            <p>
              Entendemos a grande importância do software para execução das atividades dentro da farmácia, por isso prezamos em oferecer
              soluções com qualidade a fim de maximizar os resultados, reduzir custos e atender as expectativas de nossos clientes.
            </p>
            <div className="dev">
              <span>Feito por:</span>
              <a href="https://insanydesign.com/" target="_blank" rel="noopener noreferrer">
                <img src={LogoInsany} alt="Logo Insany Design" />
              </a>
            </div>
          </div>
        </div>
      </ContentFooter>
    )
  }
}

export default Footer
