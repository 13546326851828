import styled from 'styled-components'

export const StyleHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 80px;
  background-color: #ffffff;
  z-index: 20;
  box-shadow: 0 0 30px -3px rgba(0, 0, 0, 0.1);

  .menu-button {
    display: block;
    width: 23px;
    padding: 8px 0;
    cursor: pointer;
    transition: 0.2s all cubic-bezier(0.4, -0.1, 0, 1.1);
    display: none;
    @media (max-width: 1050px) {
      display: block;
    }
  }
  a {
    &.menu-button {
      &:hover {
        .menu-icon {
          width: 23px;
          transition: 0.2s all cubic-bezier(0.4, -0.1, 0, 1.1);
          &:after {
            width: 23px;
            transition: 0.2s all cubic-bezier(0.4, -0.1, 0, 1.1);
          }
        }
      }
    }
  }
  .menu-icon {
    width: 23px;
    height: ${props => (props.menuMobile ? '0px' : '3px')};
    background-color: #0095da;
    display: block;
    position: relative;
    border-radius: 21%;
    transition: 0.2s all cubic-bezier(0.4, -0.1, 0, 1.1);
  }
  .menu-icon::before,
  .menu-icon::after {
    content: '';
    display: block;
    height: 3px;
    background-color: #0095da;
    position: absolute;
    right: 0;
    border-radius: 21%;
    transition: 0.2s all cubic-bezier(0.4, -0.1, 0, 1.1);
  }
  .menu-icon::before {
    margin-top: ${props => (props.menuMobile ? '0px' : '-8px')};
    transform: ${props => (props.menuMobile ? 'rotate(-45deg)' : 'rotate(0deg)')};
    width: 23px;
    transition: 0.2s all cubic-bezier(0.4, -0.1, 0, 1.1);
  }
  .menu-icon::after {
    margin-top: ${props => (props.menuMobile ? '0px' : '8px')};
    width: ${props => (props.menuMobile ? '23px' : '16px')};
    transform: ${props => (props.menuMobile ? 'rotate(45deg)' : 'rotate(0deg)')};
    transition: 0.2s all cubic-bezier(0.4, -0.1, 0, 1.1);
  }
  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  nav {
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    justify-content: space-between;
    margin-left: 50px;
    ul {
      display: flex;
      align-items: center;
      margin-right: 46px;
      li {
        margin-left: 33px;
        &:first-child {
          margin-left: 0px;
        }
        a {
          letter-spacing: -0.2px;
          font-size: 13px;
          color: #7e8288;
          transition: all 0.3s;
          &.active {
            color: #0095da;
            font-weight: bold;
          }
          &:hover {
            color: #3395da;
            transition: all 0.3s;
          }
        }
      }
    }
    .btn {
      font-family: 'DepotNew';
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #0ac4ac;
      box-sizing: border-box;
      border-radius: 8px 0px;
      width: 218px;
      height: 40px;
      color: #0ac4ac;
      font-weight: 500;
      font-size: 14px;
      transition: all 0.3s;
      &:hover {
        background-color: #0ac4ac;
        color: #ffffff;
        transition: all 0.3s;
      }
    }
  }
  .menu-mobile {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    z-index: 30;
    transform: ${props => (props.menuMobile ? 'translateY(0%)' : 'translateY(50%)')};
    pointer-events: ${props => (props.menuMobile ? 'all' : 'none')};
    opacity: ${props => (props.menuMobile ? '1' : '0')};
    transition: all 0.4s;
    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      li {
        margin-bottom: 35px;
        &:last-child {
          margin-bottom: 0px;
        }
        a {
          color: #7e8288;
          font-size: 24px;
          font-weight: 500;
          font-family: 'DepotNew';
          &.active {
            color: #0095da;
          }
        }
      }
    }
  }

  @media (max-width: 1050px) {
    nav {
      margin: 0;
      flex: initial;
      ul {
        display: none;
      }
      .btn {
        display: none;
      }
    }
  }
  @media (max-width: 480px) {
    width: 100%;
    height: 60px;
    .logo {
      img {
        width: 149px;
      }
    }
  }
`
